<template>
  <div class="tablePage">
    <router-link to="/" class="header">当前位置： 首页 / 我的考核任务</router-link>
    <a-form
      :model="where"
      :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
    >
    <a-row>
        <a-col :lg="8" :md="12" :sm="24" :xs="24">
          <a-form-item label="任务名称:" labelAlign="right">
            <a-select
              v-model:value="where.missionId"
              show-search
              placeholder="请输入任务名称或选择"
              :filter-option="filterOption"
            >
              <a-select-option
                v-for="(item, index) in missionNameData"
                :key="index"
                :label="item.missionName"
                :value="item.missionId"
                >{{ item.missionName }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24" :xs="24">
          <a-form-item label="任务状态:" labelAlign="right">
            <a-select
              v-model:value="where.state"
              placeholder="全部"
              :options="stateData"
            ></a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24" :xs="24">
          <a-form-item class="ele-text-right" :wrapper-col="{ span: 24 }">
            <a-space>
              <a-button type="primary" @click="reload">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-space>
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24" :xs="24">
          <a-form-item label="发布时间:" labelAlign="right">
            <a-range-picker
              v-model:value="where.publishTime"
              show-time
              value-format="YYYY-MM-DD hh:mm:ss"
              class="ele-fluid"
              @change="publishTimeChange"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24" :xs="24">
          <a-form-item label="考核截止时间:" labelAlign="right">
            <a-range-picker
              v-model:value="where.endTime"
              show-time
              value-format="YYYY-MM-DD hh:mm:ss"
              class="ele-fluid"
              @change="publishTimeChange"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <div class="filter right">
      <div class="item left" @click="handleFilter('publishTime')">
        发布时间
        <i
          class="bg"
          :class="
            orderBy == 'publishTime' && sort != 0
              ? `${sort == 1 ? 'desc' : 'asc'}`
              : ''
          "
        ></i>
      </div>
      <div class="item left" @click="handleFilter('endTime')">
        截止时间
        <i
          class="bg"
          :class="
            orderBy == 'endTime' && sort != 0
              ? `${sort == 1 ? 'desc' : 'asc'}`
              : ''
          "
        ></i>
      </div>
    </div>
    <a-empty style="margin-top:40px;" v-show="list.length == 0" />
    <block v-for="(item, index) in list" :key="index">
      <item :itemData="item" />
    </block>
    <div class="foote">
      <a-pagination
        @change="handlePaginationChange"
        v-model:current="page"
        :total="total"
        show-less-items
        :show-total="(total) => `共${total}条数据`"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import item from './modules/myassessmentTaskItem.vue'
export default {
  name: "MyAssessmentTask",
  components:{
      item
  },
  data() {
    return {
      list:[],
      page: 1,
      total: 0,
      // 表格搜索条件
      where: {},
      //筛选key
      orderBy: "",
      //筛选value 0默认  1 desc降序 2 asc升序
      sort: 0,
      // 表格选中数据
      selection: [],
      // 当前编辑数据
      current: {},
      selectData: [],
      stateData: [{
        value:0,
        label:'全部'
      },{
        value:1,
        label:'进行中'
      },{
        value:2,
        label:'已完成'
      },{
        value:3,
        label:'已过期'
      }],
      missionNameData: [],
    };
  },
  created() {
    //获取table
    this.getTable();
    //获取专业科目
    this.getMission();
  },
  methods: {
      publishTimeChange(time,timeString){
      this.where.publishTimeStart = time[0] || '';
      this.where.publishTimeEnd = time[1] || '';
    },
    endTimeChange(time,timeString){
      this.where.endTimeStart = time[0] || '';
      this.where.endTimeEnd = time[1] || '';
    },
    //筛选 publishTime endTime
    handleFilter(type) {
      if (type == this.orderBy) {
        if (this.sort == 2) {
          this.sort = 0;
          this.orderBy = "";
        } else {
          this.sort = 2;
        }
        return;
      }
      this.sort = 1;
      this.orderBy = type;
      this.page = 1;
      this.$nextTick(()=>this.getTable());
    },
    filterOption(input, option) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    async getTable() {
      try {
        let res = await this.$http.post(this.$interface.myMission, {
          page: this.page,
          pageSize: 10,
          missionType:'assess',
          orderBy:this.orderBy,
          sort:this.sort == 1?'desc':this.sort==2?'asc':'',
          ...this.where,
        });
        let data = res.data || {};
        this.total = data.count;
        this.list = data.data || [];
      } catch (err) {}
    },
    async getMission() {
      try {
        let res = await this.$http.get(this.$interface.missionSelect+'?missionType=assess');
        this.missionNameData = res.data.data || [];
      } catch (err) {}
    },
    
    //分页改变
    handlePaginationChange(page, pageSize) {
      this.page = page;
      this.$nextTick(() => this.getTable());
    },
    /* 搜索 */
    reload() {
      this.page = 1;
      this.$nextTick(() => this.getTable());
    },
    /*  重置搜索 */
    reset() {
      this.where = {}; 
      this.page = 1;
      this.$nextTick(() => this.getTable());
    },
  },
  computed: {
    ...mapGetters(["classIndex"]),
  },
  watch:{
    classIndex(val){ 
      //获取专业科目
      this.getMission();
      this.reset();
    }
  }
};
</script>
<style lang="scss">
@import "index.scss";
</style>